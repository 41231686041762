import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// components
import Iconify from "../components/iconify";
// sections
import {
	AppCurrentVisits,
	AppWebsiteVisits,
	AppWidgetSummary,
} from "../sections/@dashboard/app";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
	const theme = useTheme();

	return (
		<>
			<Helmet>
				<title> Dashboard | Castlemilk Pantry </title>
			</Helmet>

			<Container maxWidth="xl">
				<Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
				</Typography>

				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary title="Weekly Sales" total={183} icon={"ant-design:shop-filled"} />
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary title="New Users" total={10} color="info" icon={"ant-design:android-filled"} />
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary title="Donations" total={5000} color="warning" icon={"ant-design:heart-filled"} />
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary title="Volunteers" total={14} color="error" icon={"ant-design:skin-filled"} />
					</Grid>

					<Grid item xs={12} md={6} lg={8}>
						<AppWebsiteVisits
							title="Monthly Visits"
							subheader="(+43%) than last year"
							chartLabels={[
								"01/01/2023",
								"02/01/2023",
								"03/01/2023",
								"04/01/2023",
								"05/01/2023",
								"06/01/2023",
								"07/01/2023",
								"08/01/2023",
								"09/01/2023",
								"10/01/2023",
								"11/01/2023",
							]}
							chartData={[
								{
									name: "Regular Customers",
									type: "area",
									fill: "gradient",
									data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
								},
								{
									name: "New customers",
									type: "area",
									fill: "gradient",
									data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
								},

							]}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<AppCurrentVisits
							title="Visitors and dietary requirements"
							chartData={[
								{ label: "No needs", value: 4344 },
								{ label: "Vegetarian", value: 5435 },
								{ label: "Kosher", value: 1443 },
								{ label: "Halal", value: 4443 },
							]}
							chartColors={[
								theme.palette.primary.main,
								theme.palette.info.main,
								theme.palette.warning.main,
								theme.palette.error.main,
							]}
						/>
					</Grid>

				</Grid>
			</Container>
		</>
	);
}
