import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import LogoCastlemilk	from "../../../public/assets/images/castlemilk-logo.png";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

	const logo = (
		<Box
			component="img"
			src={LogoCastlemilk}
			sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
		/>
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return (
		<Link
			to="/dashboard/app"
			component={RouterLink}
			sx={{ display: "contents" }}
		>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	sx: PropTypes.object,
	disabledLink: PropTypes.bool,
};

export default Logo;
