import React, { useEffect } from "react";

const TwitterContainer = () => {

	useEffect(() => {
		const anchor = document.createElement("a");
		anchor.setAttribute("class", "twitter-timeline");
		anchor.setAttribute("data-theme", "white");
		anchor.setAttribute("data-tweet-limit", "3");
		anchor.setAttribute("data-chrome", "nofooter noborders");
		anchor.setAttribute("href", "https://twitter.com/C_milkPantry");
		document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

		const script = document.createElement("script");
		script.setAttribute("src", "https://platform.twitter.com/widgets.js");
		document.getElementsByClassName("twitter-embed")[0].appendChild(script);
	}, []);

	return (
		<section className="twitterContainer">
			<div className="twitter-embed"></div>
		</section>
	);
};

export default TwitterContainer;