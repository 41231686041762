import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/authSlice";
import { auth } from "../../../firebase";
import imageAvatar from "../../../../public/assets/images/avatars/avatar_24.jpg";


// @mui
import { alpha } from "@mui/material/styles";
import {
	Box,
	Divider,
	Typography,
	Stack,
	MenuItem,
	Avatar,
	IconButton,
	Popover,
} from "@mui/material";
import staffList from "./staffList";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
	// {
	// 	label: "Home",
	// 	icon: "eva:home-fill",
	// },
	// {
	// 	label: "Profile",
	// 	icon: "eva:person-fill",
	// },
	// {
	// 	label: "Settings",
	// 	icon: "eva:settings-2-fill",
	// },
];

// ----------------------------------------------------------------------


export default function AccountPopover() {
	const [open, setOpen] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userAccountEmail = useSelector((state) => state.data.auth.user?.email);

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const handleLogOut = async () => {
		try {
			await auth.signOut();
			await dispatch(logoutUser());
			navigate("/login", { replace: false });
		} catch (error) {
			console.error("Error signing out:", error);
		}
		setOpen(null);
	};

	// Find the staff member with a matching email
	const matchedStaff = staffList.find(
		(staff) => staff.email === userAccountEmail
	);


	console.log("image", matchedStaff ? matchedStaff.userImage : null);
	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						"&:before": {
							zIndex: 1,
							content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				{/* <Avatar alt="Remy Sharp" src={matchedStaff ? matchedStaff.userImage : null} /> */}
				<Avatar alt="Pantry Staff" src={imageAvatar} />
			</IconButton>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 0,
						mt: 1.5,
						ml: 0.75,
						width: 180,
						"& .MuiMenuItem-root": {
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle2" noWrap>
						{matchedStaff ? matchedStaff.userName : "Loading User name"}
					</Typography>
					<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
						{userAccountEmail || "Loading email"}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Stack sx={{ p: 1 }}>
					{MENU_OPTIONS.map((option) => (
						<MenuItem key={option.label} onClick={handleClose}>
							{option.label}
						</MenuItem>
					))}
				</Stack>

				<Divider sx={{ borderStyle: "dashed" }} />

				<MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
					Logout
				</MenuItem>
			</Popover>
		</>
	);
}
