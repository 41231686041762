// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// const initialState = {
// 	status: "idle",
// 	error: null,
// };

// export const insertPayment = createAsyncThunk(
// 	"payment/insertPayment",
// 	async (paymentData) => {
// 		try {
// 			const response = await fetch("/api/new-payment", {
// 				method: "POST",
// 				headers: {
// 					"Content-Type": "application/json",
// 				},
// 				body: JSON.stringify(paymentData),
// 			});
// 			if (!response.ok) {
// 				throw new Error("Failed to insert payment");
// 			}
// 			const data = await response.json();
// 			console.log(data,"payment-slice");
// 			return { data, userId: paymentData[0] };
// 		} catch (error) {
// 			throw new Error(error.message);
// 		}
// 	}
// );

// const paymentSlice = createSlice({
// 	name: "paymentInsertion",
// 	initialState,
// 	reducers: {
// 		clearPaymentStatus: (state) => {
// 			state.status = "idle";
// 			state.error = null;
// 		},
// 	},
// 	extraReducers: (builder) => {
// 		builder
// 			.addCase(insertPayment.pending, (state) => {
// 				state.status = "loading";
// 				state.error = null;
// 			})
// 			.addCase(insertPayment.fulfilled, (state) => {
// 				state.status = "succeeded";
// 			})
// 			.addCase(insertPayment.rejected, (state, action) => {
// 				state.status = "failed";
// 				state.error = action.error.message;
// 			});
// 	},
// });

// export const { clearPaymentStatus } = paymentSlice.actions; //paymentInsertionActions
// export default paymentSlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
	status: "idle",
	error: null,
};

export const insertPayment = createAsyncThunk(
	"payment/insertPayment",
	async (paymentData) => {
		try {
			const response = await fetch("/api/new-payment", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(paymentData),
			});
			if (!response.ok) {
				throw new Error("Failed to insert payment");
			}
			const data = await response.json();
			console.log(data,"payment-slice");
			return { data, userId: paymentData[0] };
		} catch (error) {
			throw new Error(error.message);
		}
	}
);

export const paymentSlice = createSlice({
	name: "paymentInsertion", // Keep the same name as in root reducer
	initialState,
	reducers: {
		clearPaymentStatus: (state) => {
			state.status = "idle";
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(insertPayment.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(insertPayment.fulfilled, (state) => {
				state.status = "succeeded";
			})
			.addCase(insertPayment.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const { clearPaymentStatus } = paymentSlice.actions;
export default paymentSlice.reducer;