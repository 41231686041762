// import PropTypes from "prop-types";
// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { styled, alpha } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
// import Drawer from "@mui/material/Drawer";
// import Typography from "@mui/material/Typography";
// import Avatar from "@mui/material/Avatar";
// import account from "../../../../public/assets/icons/navbar/account_circle_black_24dp.svg";
// import useResponsive from "../../../hooks/useResponsive";
// import Scrollbar from "../../../components/scrollbar";
// import NavSection from "../../../components/nav-section";
// import navConfig from "./config";
// import logo from "../../../../public//assets/images/castlemilk-logo.png";


// // ----------------------------------------------------------------------

// const NAV_WIDTH = 280;

// const StyledAccount = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	alignItems: "center",
// 	padding: theme.spacing(2, 2.5),
// 	borderRadius: Number(theme.shape.borderRadius) * 1.5,
// 	backgroundColor: alpha(theme.palette.grey[500], 0.12),
// }));

// // ----------------------------------------------------------------------

// Nav.propTypes = {
// 	openNav: PropTypes.bool,
// 	onCloseNav: PropTypes.func,
// };

// export default function Nav({ openNav, onCloseNav }) {
// 	const { pathname } = useLocation();

// 	const isDesktop = useResponsive("up", "lg");

// 	useEffect(() => {
// 		if (openNav) {
// 			onCloseNav();
// 		}
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [pathname]);

// 	const renderContent = (
// 		<Scrollbar
// 			sx={{
// 				height: 1,
// 				"& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
// 			}}
// 		>
// 			<Box sx={{ px: 2.5, py: 3,display: "flex",
// 				justifyContent: "center" }}>
// 				<img src={logo} />
// 			</Box>

// 			<Box sx={{ mb: 5, mx: 2.5 }}>
// 				<Link underline="none">
// 					<StyledAccount>
// 						<Avatar src={account.photoURL} alt="photoURL" />

// 						<Box sx={{ ml: 2 }}>
// 							<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
// 								Kelly Burns
// 							</Typography>

// 							<Typography variant="body2" sx={{ color: "text.secondary" }}>
// 								Pantry director
// 							</Typography>
// 						</Box>
// 					</StyledAccount>
// 				</Link>
// 			</Box>

// 			<NavSection data={navConfig} />

// 			<Box sx={{ flexGrow: 1 }} />

// 			<Box sx={{ px: 2.5, pb: 3, mt: 10 }}>

// 			</Box>
// 		</Scrollbar>
// 	);

// 	return (
// 		<Box
// 			component="nav"
// 			sx={{
// 				flexShrink: { lg: 0 },
// 				width: { lg: NAV_WIDTH },
// 			}}
// 		>
// 			{isDesktop ? (
// 				<Drawer
// 					open
// 					variant="permanent"
// 					PaperProps={{
// 						sx: {
// 							width: NAV_WIDTH,
// 							bgcolor: "background.default",
// 							borderRightStyle: "dashed",
// 						},
// 					}}
// 				>
// 					{renderContent}
// 				</Drawer>
// 			) : (
// 				<Drawer
// 					open={openNav}
// 					onClose={onCloseNav}
// 					ModalProps={{
// 						keepMounted: true,
// 					}}
// 					PaperProps={{
// 						sx: { width: NAV_WIDTH },
// 					}}
// 				>
// 					{renderContent}
// 				</Drawer>
// 			)}
// 		</Box>
// 	);
// }


import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import account from "../../../../public/assets/icons/navbar/account_circle_black_24dp.svg"; // Default avatar
import useResponsive from "../../../hooks/useResponsive";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import navConfig from "./config";
import logo from "../../../../public//assets/images/castlemilk-logo.png";
import { useSelector } from "react-redux";
import staffList from "../header/staffList"; // Make sure the import path is correct
import imageAvatar from "../../../../public/assets/images/avatars/avatar_24.jpg";


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
	const { pathname } = useLocation();
	const isDesktop = useResponsive("up", "lg");
	const user = useSelector((state) => state.data.auth.user);
	const matchedStaff = staffList.find((staff) => staff.email === user?.email);
	const userName = matchedStaff ? matchedStaff.userName : "Loading...";
	const userRole = matchedStaff ? matchedStaff.role : "Loading...";
	const userImage = matchedStaff ? matchedStaff.userImage : account; // Use default avatar if no image is found

	useEffect(() => {
		if (openNav) {
			onCloseNav();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
			}}
		>
			<Box sx={{ px: 2.5, py: 3, display: "flex", justifyContent: "center" }}>
				<img src={logo} />
			</Box>

			<Box sx={{ mb: 5, mx: 2.5 }}>
				<Link underline="none">
					<StyledAccount>
						<Avatar src={imageAvatar} alt={userName} />

						<Box sx={{ ml: 2 }}>
							<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
								{userName}
							</Typography>

							<Typography variant="body2" sx={{ color: "text.secondary" }}>
								{userRole}
							</Typography>
						</Box>
					</StyledAccount>
				</Link>
			</Box>

			<NavSection data={navConfig} />

			<Box sx={{ flexGrow: 1 }} />

			<Box sx={{ px: 2.5, pb: 3, mt: 10 }} />
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV_WIDTH },
			}}
		>
			{isDesktop ? (
				<Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV_WIDTH,
							bgcolor: "background.default",
							borderRightStyle: "dashed",
						},
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: { width: NAV_WIDTH },
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}
