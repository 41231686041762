import { Helmet } from "react-helmet-async";
// @mui
import Toolbar from "@mui/material/Toolbar";
import OutlinedInput from "@mui/material/OutlinedInput";

import { styled, alpha } from "@mui/material/styles";

import Iconify from "../components/iconify/Iconify";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
	height: 96,
	display: "flex",
	justifyContent: "space-between",
	padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
	width: 240,
	transition: theme.transitions.create(["box-shadow", "width"], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	"&.Mui-focused": {
		width: 320,
		boxShadow: theme.customShadows.z8,
	},
	"& fieldset": {
		borderWidth: "1px !important",
		borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
	},
}));

export default function ServicePage() {

	return (
		<h1>Service Page</h1>
	);

}