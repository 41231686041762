// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

const CardInfluencer = (members) => {
	return (
		<>
			{members.members.comments ? (
				<Card
					sx={{
						height: "100%",
						backgroundColor: "warning.main",
					}}
				>
					<Typography
						variant="h6"
						sx={{
							marginBottom: 2.3,
							mt: 2.5,
							marginLeft: 1.5,
							lineHeight: 1.0,
							fontWeight: 600,
							fontSize: "2.5rem !important",
						}}
					>
            Important information
					</Typography>
					<CardContent>
						<Typography color="text.secondary">Needed to update</Typography>
						<Typography
							sx={{ mb: 0.5, lineHeight: 1 }}
							variant="h5"
							component="div"
							color="text.primary"
						>
							{members.members.comments}
						</Typography>
					</CardContent>
				</Card>
			) : null}
		</>
	);
};

export default CardInfluencer;
