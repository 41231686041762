import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../redux/usersTableSlice";

import Iconify from "../components/iconify";
import QrCode from "../components/qr-code/QrCode";

const personalInfoSteps = [
	{
		label: "Basic Information",
		description: "Enter your basic information.",
		fields: ["Forename", "Surname", "Household_Number"],
	},
	{
		label: "Contact Information",
		description: "Enter your contact information.",
		fields: ["Address", "Postcode", "Email", "Mobile"],
	},
	{
		label: "Additional Information",
		description: "Enter your housing association.",
		fields: ["Housing_Association"],
	},
	{
		label: "Subscribe to newsletter",
		description: "Want to received news at your email.",
		fields: ["Opt_in_mail"],
	},
	{
		label: "Dietary Requirements",
		description: "Select your dietary requirements.",
		fields: ["Dietary_Requirements"],
	},
];

const steps = [
	...personalInfoSteps,
	{
		label: "Privacy notice",
		description: "I am aware that my personal data will be processed in accordance with Ardenglen Housing Association's Privacy notice, which is available at www.ardenglen.co.uk and is also available at the Castlemilk Pantry. \nI am aware that any data collected in relation to gas/electric will be shared with my consent with third parties who may be able to assist, including, but not limited to the Fuel Bank Foundation, Money Matters, Mega Fund, Home Heat Fund and HES."  ,
		fields: ["Accept_Privacy_notice"],
	},
];

export default function VerticalLinearStepper() {
	const dispatch = useDispatch();
	const [openDialog, setOpenDialog] = useState(false);
	const [openBackDrop, setOpenBackDrop] = useState(false);
	const [responseData, setResponseData] = useState("");
	const [isInsertionSuccessSnackbarOpen, setIsInsertionSuccessSnackbarOpen]
    = useState(false);
	const [isInsertionErrorSnackbarOpen, setIsInsertionErrorSnackbarOpen]
    = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [activeStep, setActiveStep] = useState(0);
	const formRefs = personalInfoSteps.reduce((acc, step) => {
		step.fields.forEach((field) => {
			acc[field] = useRef(null);
		});
		return acc;
	}, {});
	const [formData, setFormData] = useState({
		Forename: "",
		Surname: "",
		Household_Number: "",
		Address: "",
		Postcode: "",
		Email: "",
		Opt_in_mail: false,
		Mobile: "",
		Housing_Association: "",
		Dietary_Requirements: "",
		Accept_Privacy_notice: false,

	});
	const requiredFields = [
		"Address",
		"Forename",
		"Surname",
		"Household_Number",
		"Postcode",
		"Housing_Association",
		"Dietary_Requirements",
		"Accept_Privacy_notice",
	];
	const [errors, setErrors] = useState({});

	const handleCloseSnackbar = () => {
		setIsInsertionErrorSnackbarOpen(false);
		setIsInsertionSuccessSnackbarOpen(false);
	};

	const handleNext = () => {
		// Validate current step fields before proceeding to the next step
		if (validateStep(activeStep)) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setFormData({
			Forename: "",
			Surname: "",
			Household_Number: "",
			Address: "",
			Postcode: "",
			Email: "",
			Opt_in_mail: false,
			Mobile: "",
			Housing_Association: "",
			Dietary_Requirements: "",
			Accept_Privacy_notice: false,

		});
		setErrors({});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setOpenBackDrop(true);

		try {
			const response = await fetch("/api/user-registration", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			const data = await response.json();
			setOpenBackDrop(false);
			setResponseData(data);

			if (!response.ok) {
				throw new Error(data.error || "Failed to insert member");
			}

			setIsInsertionSuccessSnackbarOpen(true);
			dispatch(fetchUsers());
			handleReset();
		} catch (error) {
			setIsInsertionErrorSnackbarOpen(true);
			setErrorMessage(error.message || "Unknown error occurred");
			setOpenBackDrop(false);
		}
	};

	const validateStep = (step) => {
		const stepFields = personalInfoSteps[step].fields;
		const stepErrors = {};
		let isValid = true;
		stepFields.forEach((field) => {
			if (requiredFields.includes(field) && !formData[field]) {
				stepErrors[field] = true;
				isValid = false;
			} else {
				stepErrors[field] = false;
			}
		});
		setErrors((prevErrors) => ({
			...prevErrors,
			...stepErrors,
		}));
		return isValid;
	};

	// const validateAllFields = () => {
	// 	const allErrors = {};
	// 	let isValid = true;
	// 	Object.keys(formData).forEach((field) => {
	// 		if (requiredFields.includes(field) && !formData[field]) {
	// 			allErrors[field] = true;
	// 			isValid = false;
	// 		} else {
	// 			allErrors[field] = false;
	// 		}
	// 	});
	// 	setErrors(allErrors);
	// 	return isValid;
	// };

	return (
		<>
			<Helmet>
				<title>Membership</title>
			</Helmet>

			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="left"
					gap={"10px"}
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
            Registration
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill" />}
					>
            New member
					</Button>
				</Stack>
				<Card>
					<CardContent>
						<Box sx={{ maxWidth: 400 }}>
							<Stepper activeStep={activeStep} orientation="vertical">
								{steps.map((step, index) => (
									<Step key={step.label}>
										<StepLabel
											optional={
												index === 5 ? (
													<Typography variant="caption">Last step</Typography>
												) : null
											}
										>
											{step.label}
										</StepLabel>
										<StepContent>
											<Typography>{step.description}</Typography>
											<Box sx={{ mb: 2 }}>
												{step.fields.map((field) => (
													<Box key={field} sx={{ mb: 2 }}>
														{field === "Opt_in_mail" || field === "Accept_Privacy_notice" ? (
															<FormControlLabel
																control={
																	<Checkbox
																		inputRef={formRefs[field]}
																		checked={formData[field]}
																		onChange={(event) =>
																			setFormData((prevData) => ({
																				...prevData,
																				[field]: event.target.checked,
																			}))
																		}
																		color={errors[field] ? "error" : "primary"}
																	/>
																}
																label={field}
															/>
														) : field === "Housing_Association"
                              || field === "Dietary_Requirements" ? (
																<FormControl
																	fullWidth
																	required={requiredFields.includes(field)}
																	error={errors[field]}
																>
																	<Select
																		value={formData[field]}
																		onChange={(event) =>
																			setFormData((prevData) => ({
																				...prevData,
																				[field]: event.target.value,
																			}))
																		}
																	>
																		{field === "Housing_Association" ? (
																			<MenuItem value="">
                                      Select Housing Association
																			</MenuItem>
																		) : (
																			<MenuItem value="">
                                      Select Dietary Requirement
																			</MenuItem>
																		)}
																		{field === "Housing_Association"
																			? [
																				"CAHA",
																				"NVHA",
																				"GHA",
																				"CRHA",
																				"AHA",
																				"THENUE",
																				"HOME GROUP",
																				"TEMP HOUSING",
																				"UNKNOWN-OTHER",
																			].map((option) => (
																				<MenuItem key={option} value={option}>
																					{option}
																				</MenuItem>
																			))
																			: ["VEGAN", "VEGETARIAN", "HALAL","KOSHER","NO RESTRICTION"].map(
																				(option) => (
																					<MenuItem key={option} value={option}>
																						{option}
																					</MenuItem>
																				)
																			)}
																	</Select>
																	{errors[field] && (
																		<FormHelperText>
                                    This field is required
																		</FormHelperText>
																	)}
																</FormControl>
															) : (
																<TextField
																	inputRef={formRefs[field]}
																	required={requiredFields.includes(field)}
																	type={
																		field === "Household_Number"
                                  || field === "Mobile"
								//   save and send people living with you
																			? "number"
																			: field === "Email"
																				? "email"
																				: "text"
																	}
																	label={field}
																	fullWidth
																	sx={{ mt: 1 }}
																	error={errors[field]}
																	helperText={
																		errors[field] ? "This field is required" : ""
																	}
																	value={formData[field]}
																	onChange={(event) =>
																		setFormData((prevData) => ({
																			...prevData,
																			[field]: event.target.value,
																		}))
																	}
																/>
															)}
													</Box>
												))}
												<div>
													<Button
														variant="contained"
														onClick={
															activeStep === steps.length - 1
																? handleSubmit
																: handleNext
														}
														sx={{ mt: 1, mr: 1 }}
													>
														{activeStep === steps.length - 1
															? "Finish"
															: "Continue"}
													</Button>
													<Button
														disabled={activeStep === 0}
														onClick={handleBack}
														sx={{ mt: 1, mr: 1 }}
													>
                            Back
													</Button>
												</div>
											</Box>
										</StepContent>
									</Step>
								))}
							</Stepper>
							{openBackDrop && (
								<Backdrop
									sx={{
										color: "#fff",
										zIndex: (theme) => theme.zIndex.drawer + 1,
									}}
									open={openBackDrop}
								>
									<CircularProgress color="inherit" />
								</Backdrop>
							)}
							{isInsertionErrorSnackbarOpen && (
								<Stack spacing={2} sx={{ width: "100%" }}>
									<Snackbar
										open={isInsertionErrorSnackbarOpen}
										autoHideDuration={6000}
										onClose={handleCloseSnackbar}
										anchorOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
										sx={{
											top: "30px",
											position: "absolute",
										}}
									>
										<Alert
											onClose={handleCloseSnackbar}
											severity="error"
											sx={{ width: "100%" }}
										>
											<AlertTitle>Error</AlertTitle>
											<Divider />
											<Typography variant="h2">
                        Failed to insert new member
											</Typography>
											<Divider />

											<Typography variant="h4">Error{responseData.message}</Typography>
										</Alert>
									</Snackbar>
								</Stack>
							)}
							{isInsertionSuccessSnackbarOpen && (
								<Stack spacing={2} sx={{ width: "100%" }}>
									<Snackbar
										open={isInsertionSuccessSnackbarOpen}
										autoHideDuration={30000}
										onClose={handleCloseSnackbar}
										anchorOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
										sx={{
											top: "10px",
											position: "absolute",
										}}
									>
										<Alert
											onClose={handleCloseSnackbar}
											severity="success"
											sx={{ width: "100%" }}
										>
											<AlertTitle>New member created</AlertTitle>
											<Divider />
											<Typography variant="h3">Welcome {responseData.name}</Typography>
											<QrCode id={responseData.id} name={responseData.name} user_id={responseData.user_id} last_name={responseData.last_name} />

										</Alert>
									</Snackbar>
								</Stack>
							)}
						</Box>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
