import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import CardMembership from "../../components/cards/CardMembership";
import CardInfluencer from "../../components/cards/CardInfluencer";
import CardWithCollapse from "../../components/cards/CardWithCollapse";
import CardInformationToUsers from "../../components/cards/CardInformationToUsers.js";

const PantryProfile = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [members, setMembers] = useState([null]);
	const urlParams = useParams();

	useEffect(() => {
		setIsLoading(true);
		fetch(`/api/user-profile?uuid=${urlParams.uuid}`)
			.then((response) => {
				if (!response.ok) {
					throw Error("There was an error getting the client data");
				}
				return response.json();
			})
			.then((data) => {
				setMembers(data[0]);
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [urlParams.uuid]);

	return (

		<Grid container spacing={3}>
			<Grid item xs={12} sm={12} md={12}>
				<Stack sx={{ width: "100%" }} spacing={1}>
					{members.membership_status === "Idle" && (
						<Alert variant="filled" severity="info" sx={{ bgcolor: "#a6a6a6" }}  >
							{/* Gray */}
        Your membership status is inactive and unpaid.
						</Alert>
					)}

					{members.membership_status === "Inactive" && (
						<Alert variant="filled" severity="warning">
        Your membership status is inactive.
						</Alert>
					)}

					{members.membership_status === "Active" && (
						<Alert variant="filled" severity="success">
							{/* Green */}
        Your membership status is active.
						</Alert>
					)}

					{members.membership_status === "Active" && members.comments && (
						<Alert variant="filled" severity="warning">
        Active but information is required.
						</Alert>
					)}

					{members.is_shop_on_behalf_active && members.membership_status && (
						<Alert variant="filled" severity="success" sx={{ bgcolor: "#9774c7" }}>
							{/* Custom color: Purple */}
        Shopping on behalf of another member.
						</Alert>
					)}

					{members.membership_status === "Expired" && (
						<Alert variant="filled" severity="error">
        Membership active but annual payment due.
						</Alert>
					)}


				</Stack>
			</Grid>
			<Grid item xs={12} sm={12} md={4}>
				<CardMembership members={members} />
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<CardInformationToUsers members={members} />
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<CardInfluencer members={members} />
			</Grid>
			{members.who_you_shop_on_behalf_for && (
				<Grid item xs={12} sm={6} md={4}>
					<CardWithCollapse members={members} />
				</Grid>)
			}
		</Grid>
	);
};

export default PantryProfile;
