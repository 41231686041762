// component
import SvgColor from "../../../components/svg-color";
import dashboardIcon from "../../../../public/assets/icons/navbar/space_dashboard_black.svg";
import userIcon from "../../../../public/assets/icons/navbar/people_black_24dp.svg";
import serviceIcon from "../../../../public/assets/icons/navbar/support_agent_black_24dp.svg";
import scanningIcon from "../../../../public/assets/icons/navbar/qr_code_scanner_black_24dp.svg";
import loginIcon from "../../../../public/assets/icons/navbar/lock_person_black_24dp.svg";
import DailyReport from "../../../../public/assets/icons/navbar/assignment_black_24dp.svg";
import NewMember from "../../../../public/assets/icons/navbar/person_add_black_24dp.svg";

const navConfig = [
	{
		title: "dashboard",
		path: "/dashboard/app",
		icon: <SvgColor src={dashboardIcon}sx={{ width: 1, height: 1 }} />,
		// icon: icon("ic_analytics"),
	},
	{
		title: "membership",
		path: "/dashboard/user",
		icon: <SvgColor src={userIcon}sx={{ width: 1, height: 1 }} />,
	},
	// {
	// 	title: "product",
	// 	path: "/dashboard/products",
	// 	icon: icon("ic_cart"),
	// },
	{
		title: "scanning",
		path: "/dashboard/scanning",
		icon: <SvgColor src={scanningIcon}sx={{ width: 1, height: 1 }} />,
	},
	{
		title: "Reports",
		path: "/dashboard/daily-reports",
		icon: <SvgColor src={DailyReport}sx={{ width: 1, height: 1 }} />,
	},
	{
		title: "Registration",
		path: "/dashboard/registration",
		icon: <SvgColor src={NewMember}sx={{ width: 1, height: 1 }} />,
	},
	{
		title: "service",
		path: "/dashboard/service",
		icon: <SvgColor src={serviceIcon}sx={{ width: 1, height: 1 }} />,
	},
	{
		title: "login",
		path: "/login",
		icon: <SvgColor src={loginIcon}sx={{ width: 1, height: 1 }} />,
	},
];

export default navConfig;
