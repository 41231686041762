import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Routes from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, setLoading } from "./redux/authSlice";

export default function App() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.data.auth.user);
	const role = useSelector((state) => state.data.auth.role); // Access the role

	useEffect(() => {
		auth.onAuthStateChanged((authUser) => {
			if (authUser) {
				dispatch(
					loginUser({
						uid: authUser.uid,
						email: authUser.email,
					})
				);
				dispatch(setLoading(false));
			} else {
				console.log("User is not logged in");
			}
		});
	}, []);

	return (
		<HelmetProvider>
			<BrowserRouter>
				<ThemeProvider>
					<ScrollToTop />
					<StyledChart />
					<Routes />
				</ThemeProvider>
			</BrowserRouter>
		</HelmetProvider>
	);
}

