import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function RoleBasedRoute({ children, requiredRole, ...rest }) {
	const user = useSelector((state) => state.data.auth.user);
	const role = useSelector((state) => state.data.auth.role);

	if (!user) {
		return <Navigate to="/login" />;
	}

	if (!requiredRole.includes(role)) {
		return <Navigate to="/404" />;
	  }

	return children;
}

export default RoleBasedRoute;
