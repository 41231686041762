import { useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// component
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled("div")(({ theme }) => ({
	...bgBlur({ color: theme.palette.background.default }),
	top: 0,
	left: 0,
	zIndex: 99,
	width: "100%",
	display: "flex",
	position: "absolute",
	alignItems: "center",
	height: HEADER_MOBILE,
	padding: theme.spacing(0, 3),
	boxShadow: theme.customShadows.z8,
	[theme.breakpoints.up("md")]: {
		height: HEADER_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				{!open && (
					<IconButton onClick={handleOpen}>
						<Iconify icon="eva:search-fill" />
					</IconButton>
				)}

				<Slide direction="down" in={open} mountOnEnter unmountOnExit>
					<StyledSearchbar>
						<Input
							autoFocus
							fullWidth
							disableUnderline
							placeholder="Search…"
							startAdornment={
								<InputAdornment position="start">
									<Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 20 }} />
								</InputAdornment>
							}
							sx={{ mr: 1, fontWeight: "fontWeightBold" }}
						/>
						<Button variant="contained" onClick={handleClose}>
              Search
						</Button>
					</StyledSearchbar>
				</Slide>
			</div>
		</ClickAwayListener>
	);
}
