import React from "react";
// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { QRCodeSVG } from "qrcode.react";

// Styled Box component
const StyledBox = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

const CardMembership = (members) => {

	const colourStatus = (members.members.is_shop_on_behalf_active || members.members.who_you_shop_on_behalf_for)
		? {
			sx: {
				padding: (theme) => `${theme.spacing(2, 2, 2, 1.5)} !important`,
				bgcolor: "#9774c7",
			},
		}
		: {
			sx: {
				padding: (theme) => `${theme.spacing(2, 2, 2, 1.5)} !important`,
			},
		};

	return (
		<Card>
			<Grid container spacing={12}>
				<Grid item xs={12} sm={12}>
					<CardContent {...colourStatus}>
						<Typography
							variant="h6"
							sx={{
								marginBottom: 4,
								marginTop: 1.5,
								lineHeight: 0.8,
								fontWeight: 600,
								fontSize: "3.25rem !important",
							}}
						>
              Castlemilk Pantry Membership
						</Typography>
						<Box>
							<CardContent
								sx={{ display: "flex", justifyContent: "space-between" }}
							>
								<Box sx={{ ml: -2 }}>
									{/* <Typography color="text.secondary">Member</Typography>
									<Typography
										sx={{ mb: 0.5, lineHeight: 1 }}
										variant="h5"
										component="div"
									>
										{`${members.members.first_name}`}
									</Typography>
									<Typography
										sx={{ mb: 0.5, lineHeight: 1 }}
										variant="h5"
										component="div"
									>
										{`${members.members.last_name}`}
									</Typography> */}
									<Typography sx={{ mb: 1 }} color="text.secondary">
                    Card number
									</Typography>
									<Typography
										variant="h6"
										sx={{
											lineHeight: 0.5,
											fontWeight: 600,
											fontSize: "3.50rem !important",
										}}
									>
										{members.members.user_id
											? members.members.user_id.toString().padStart(3, "0")
											: null}
									</Typography>
								</Box>
								<Box sx={{ mr: -2 }}>
									<QRCodeSVG
										value={members.members.qrcode}
										size={146}
										bgColor={"#ffffff"}
										fgColor={"#212b36"}
										level={"L"}
										includeMargin={false}
									/>
								</Box>
							</CardContent>
						</Box>
					</CardContent>
				</Grid>
			</Grid>
		</Card>
	);
};

export default CardMembership;
