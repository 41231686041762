import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyD7gsxr0XKicx7ZJcNqcwWJkWTpXzmtLQ8",
	authDomain: "ardenglen-pantry-membership.firebaseapp.com",
	projectId: "ardenglen-pantry-membership",
	storageBucket: "ardenglen-pantry-membership.appspot.com",
	messagingSenderId: "180299664613",
	appId: "1:180299664613:web:eb6bc413b66eec6c2d980c",
	measurementId: "G-VSLS3N35GC",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };