import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import SimpleLayout from "./layouts/simple";
import RoleBasedRoute from "./components/private-routes/RoleBaseRoute";
import ServicePage from "./pages/ServicePage";
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ScanPage from "./pages/ScanPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import PantryProfile from "./pages/profile/PantryProfile";
import DailyReport from "./pages/DailyReport";
import Registrations from "./pages/Registrations";

export default function Router() {
	return (
		<Routes>
			<Route path="/dashboard/*" element={<DashboardLayout />}>
				<Route index element={<Navigate to="app" />} />
				<Route path="app" element={<RoleBasedRoute requiredRole={["admin", "assistant"]}><DashboardAppPage /></RoleBasedRoute>} />
				<Route path="user" element={<RoleBasedRoute requiredRole={["admin"]}><UserPage /></RoleBasedRoute>} />
				<Route path="scanning" element={<RoleBasedRoute requiredRole={["admin","assistant"]}><ScanPage /></RoleBasedRoute>} />
				<Route path="service" element={<RoleBasedRoute requiredRole={["admin","assistant"]}><ServicePage /></RoleBasedRoute>} />
				<Route path="daily-reports" element={<RoleBasedRoute requiredRole={["admin"]}><DailyReport /></RoleBasedRoute>} />
				<Route path="registration" element={<RoleBasedRoute requiredRole={["admin","assistant"]}><Registrations /></RoleBasedRoute>} />
			</Route>
			<Route path="/login" element={<LoginPage />} />
			<Route path="pantry-profile/:uuid" element={<PantryProfile />} />
			<Route path="/404" element={<SimpleLayout />}>
				<Route index element={<Page404 />} />
			</Route>
			<Route path="*" element={<Navigate to="/404" replace />} />
		</Routes>
	);
}
