import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import dd from "../../../client/src/assets/illustrations/illustration_404.svg";


// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
	return (
		<>
			<Helmet>
				<title> 404 Page Not Found | Minimal UI </title>
			</Helmet>

			<Container>
				<StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
					<Typography variant="h3" paragraph>
            Sorry, page not found or permission denied.
					</Typography>

					<Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for, probably you have no permission to enter in this page or Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
					</Typography>

					<Box sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}>
						<img src={dd}  alt="404 error messages" />
					</Box>

					<Button to="/dashboard/app" size="large" variant="contained" component={RouterLink}>
            Go to Home
					</Button>
				</StyledContent>
			</Container>
		</>
	);
}
