import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



export default function ReportsTable(props) {
	const { data, error } = props;

	if (!Array.isArray(data) || data.length === 0) {

		return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableBody>
						<TableRow>
							<TableCell>{error || "No data available"}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	const rows = data.reduce((acc, curr) => {
		acc[curr.payment_type] = (acc[curr.payment_type] || 0) + 1;
		return acc;
	}, {});

	return (
		<TableContainer component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Payment Type</TableCell>
						<TableCell>Count</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.entries(rows).map(([type, count]) => (
						<TableRow key={type}>
							<TableCell>{type}</TableCell>
							<TableCell>{count}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

