
import React from "react";
// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { QRCodeSVG } from "qrcode.react";

const QrCode = (props) => {

	return (
		<Card>
			<Grid container spacing={12}>
				<Grid item xs={12} sm={12}>
					<CardContent sx={{ padding: (theme) => `${theme.spacing(2, 2, 2,1.5)} !important` }}>
						<Box >
							<CardContent sx={{ display: "flex" , justifyContent: "space-between" }}>
								<Box sx={{ ml: -2 }}>
									<Typography  color="text.secondary">
          						Member
									</Typography>
									<Typography sx={{ mb: 0.5 ,lineHeight: 1 }} variant="h5" component="div">
										{`${props.name}`}
									</Typography>
									<Typography sx={{ mb: 0.5 ,lineHeight: 1 }} variant="h5" component="div">
										{`${props.last_name}`}
									</Typography>
									<Typography sx={{ mb: 1 }} color="text.secondary">
          						Card number
									</Typography>
									<Typography variant='h6' sx={{ lineHeight: 0.5,
										fontWeight: 600,
										fontSize: "3.50rem !important" }}>
										{props.user_id ? props.user_id.toString().padStart(3,"0") :null}
									</Typography>
								</Box>
								<Box sx={{ ml: 10 }}>
									<QRCodeSVG
										value={props.id}
										size={146}
										bgColor={"#ffffff"}
										fgColor={"#212b36"}
										level={"L"}
										includeMargin={false}
									/>
								</Box>

							</CardContent>
						</Box>
					</CardContent>
				</Grid>
			</Grid>
		</Card>
	);
};

export default QrCode;
