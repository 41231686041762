import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
// components
import Iconify from "../components/iconify";
import ReportsTable from "../components/table/ReportsTable";

import Calendar from "../components/calendar-component/Calendar";

const fetchReportUrl = "/api/getting-daily-shops-reports";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
	height: 96,
	display: "flex",
	justifyContent: "start",
	gap: 12,
	padding: theme.spacing(0, 1, 0, 3),
}));

export default function DailyReport() {
	const [initCalendarValue, setInitCalendarValue] = useState(new Date());
	const [endCalendarValue, setEndCalendarValue] = useState(new Date());
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleFetchReports = async () => {
		setLoading(true);
		const initValueQueryParam = encodeURIComponent(
			initCalendarValue.toISOString()
		);
		const endValueQueryParam = encodeURIComponent(
			endCalendarValue.toISOString()
		);
		const queryParams = `?initValue=${initValueQueryParam}&endValue=${endValueQueryParam}`;

		const url = `${fetchReportUrl}${queryParams}`;

		try {
			const response = await fetch(url);

			if (response.ok) {
				const data = await response.json();
				setData(data);
				setError(null);
				setLoading(false);
			} else {
				console.log(
					"Failed to fetch reports. Response status:",
					response.status
				);
				setError("Failed to fetch reports. Please try again later.");
				setLoading(false);
			}
		} catch (error) {
			console.error("Failed to fetch reports:", error);
			setError("Failed to fetch reports. Please try again later.");
			setLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<title> Membership </title>
			</Helmet>

			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="left"
					gap={"10px"}
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
            Daily Reports
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill" />}
						onClick={handleFetchReports}
					>
            Today's report
					</Button>
				</Stack>

				<Card>
					<StyledRoot>
						<Calendar
							value={initCalendarValue}
							changeValue={(newValue) => setInitCalendarValue(newValue)}
							label={"From date"}
						/>
						<Calendar
							value={endCalendarValue}
							changeValue={(newValue) => setEndCalendarValue(newValue)}
							label={"To date"}
						/>
						<Button
							variant="contained"
							startIcon={<Iconify icon="eva:plus-fill" />}
							onClick={handleFetchReports}
						>
              Get reports
						</Button>
					</StyledRoot>
					{error ? (
						<Alert severity="error">{error}</Alert>
					) : (
						<ReportsTable key={data.length} data={data} />
					)}
				</Card>
			</Container>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
