import PropTypes from "prop-types";
// @mui
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
// ----------------------------------------------------------------------

const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: "1px",
	height: "1px",
	overflow: "hidden",
	position: "absolute",
	whiteSpace: "nowrap",
	clip: "rect(0 0 0 0)",
};

UserListHead.propTypes = {
	order: PropTypes.oneOf(["asc", "desc"]),
	orderBy: PropTypes.string,
	rowCount: PropTypes.number,
	headLabel: PropTypes.array,
	numSelected: PropTypes.number,
	onRequestSort: PropTypes.func,
	onSelectAllClick: PropTypes.func,
};

export default function UserListHead({
	order,
	orderBy,
	rowCount,
	headLabel,
	numSelected,
	onRequestSort,
	onSelectAllClick,
}) {
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headLabel.map((headCell) => (
					<TableCell
						key={headCell.field}
						align={headCell.alignRight ? "right" : "left"}
						sortDirection={orderBy === headCell.field ? order : false}
					>
						<TableSortLabel
							hideSortIcon
							active={orderBy === headCell.field}
							direction={orderBy === headCell.field ? order : "asc"}
							onClick={createSortHandler(headCell.field)}
						>
							{headCell.label}
							{orderBy === headCell.field ? (
								<Box sx={{ ...visuallyHidden }}>{order === "desc" ? "sorted descending" : "sorted ascending"}</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
