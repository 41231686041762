import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define an initial state
const initialState = {
	users: [],
	status: "idle",
	error: null,
};

// Create an async thunk to fetch users
export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
	const response = await fetch("/api/database-users");
	return response.json();
});

// Create a user table slice
const usersTableSlice = createSlice({
	name: "usersTable",
	initialState,
	reducers: {
		// Add your reducer functions here if needed
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.users = action.payload;
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

// Selectors
// export const selectAllUsers = (state) => (state.usersTable.users);

// Export actions and reducer
export const { addUser, updateUser, deleteUser } = usersTableSlice.actions;
export default usersTableSlice.reducer;
export const usersTableReducer = usersTableSlice.reducer;
