import React from "react";
import 	{ LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns }from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function Calendar(props) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<MobileDatePicker
				label={props.label}
				value={props.value}
				onChange={props.changeValue}
			/>
		</LocalizationProvider>
	);
}
