import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
// @mui
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
// components
import Iconify from "../../../components/iconify/Iconify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/authSlice";


// ----------------------------------------------------------------------

export default function LoginForm() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const user = useSelector((state) => state.data.auth.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user) {
			navigate("/dashboard/app", { replace: true });
		}
	}, [user, navigate]);

	const handleLogin = async () => {
		try {
			const { user } = await signInWithEmailAndPassword(auth, email, password);
			dispatch(
				loginUser({
					uid: user.uid,
					email: user.email,
					displayName: user.displayName,
				})
			);
			// Let the `useEffect` with `user` handle navigation
		} catch (error) {
			console.error("Login failed:", error.message);
		}
	};

	return (
		<>
			<Stack spacing={3}>
				<TextField
					name="email"
					label="Email address"
					value={email} // removed ".value"
					onChange={(e) => setEmail(e.target.value)}
				/>

				<TextField
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"} // you can add showPassword toggle if needed
					value={password} // removed ".value"
					onChange={(e) => setPassword(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									edge="end"
								>
									<Iconify
										icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ my: 2 }}
			>
				<Checkbox name="remember" label="Remember me" />
				<Link variant="subtitle2" underline="hover">
          Forgot password?
				</Link>
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={handleLogin}
			>
        Login
			</LoadingButton>
		</>
	);
}
