// ** MUI Imports
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

const CardInfluencer = (members) => {
	return (
		<Card
			sx={{
				height: "100%",
				backgroundColor: "action.hover",
			}}
		>
			<Typography
				variant="h6"
				sx={{
					marginBottom: 2.3,
					mt: 2.5,
					marginLeft: 1.5,
					lineHeight: 1.0,
					fontWeight: 600,
					fontSize: "2.5rem !important",
				}}
			>
        Membership information
			</Typography>
			<CardContent>
				<Typography color="text.secondary">Member since</Typography>
				<Typography
					sx={{ mb: 0.5, lineHeight: 1 }}
					variant="h5"
					component="div"
				>
					{`${
						members.members.start_date
							? new Date(members.members.start_date).toLocaleDateString()
							: "Shop your membership"
					}`}
				</Typography>
				<Typography color="text.secondary">Renewal date</Typography>
				<Typography
					sx={{ mb: 0.5, lineHeight: 1 }}
					variant="h5"
					component="div"
				>
					{`${
						members.members.end_date
							? new Date(members.members.end_date).toLocaleDateString()
							: "Shop your membership"
					}`}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default CardInfluencer;
