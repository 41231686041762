import { createSlice } from "@reduxjs/toolkit";
import staffList from "../layouts/dashboard/header/staffList";

const initialState = {
	user: null,
	isLoading: true,
	role: "user",
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginUser: (state, action) => {
			state.user = action.payload;
			const matchedStaff = staffList.find((staff) => staff.email === state.user.email);
			state.role = matchedStaff ? matchedStaff.role : "user";
		},
		logoutUser: (state) => {
			state.user = null;
			state.role = "user";
		},
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
	},
});

export const { loginUser, logoutUser, setLoading } = authSlice.actions;
export default authSlice.reducer;