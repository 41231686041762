import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const StyledSection = styled("div")(({ theme }) => ({
	width: "100%",
	maxWidth: 480,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	boxShadow: theme.customShadows.card,
	backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
	const mdUp = useResponsive("up", "md");

	return (
		<>
			<Helmet>
				<title> Login | Castlemilk Pantry </title>
			</Helmet>

			<StyledRoot>
				<Logo
					sx={{
						position: "fixed",
						top: { xs: 16, sm: 24, md: 40 },
						left: { xs: 16, sm: 24, md: 40 },
					}}
				/>
				{mdUp && (
					<StyledSection>
						<Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
						</Typography>
						<img src="https://img.freepik.com/free-vector/access-control-system-abstract-concept_335657-3180.jpg?w=826&t=st=1701164428~exp=1701165028~hmac=d06cb500579fbd908484f2ed9995479861c604524ecde464c00dc535211b6e8f"
						 alt="login" />
					</StyledSection>

				)}

				<Container maxWidth="sm">
					<StyledContent>
						<Typography variant="h4" gutterBottom>
              Sign in to Castlemilk Pantry
						</Typography>

						<Divider sx={{ my: 3 }}>
							<Typography
								variant="body2"
								sx={{ color: "text.secondary" }}
							></Typography>
						</Divider>

						<LoginForm />
					</StyledContent>
				</Container>
			</StyledRoot>
		</>
	);
}
